<template>
  <header class="Header d-flex">
    <mymedel-bar :logout-url="logoutCallback" portalId="portal" :language="currentLanguage">
      <div class="list">
          <li>
            <link-router
              class-names="d-block "
              path="profile"
            >
              {{ $t('pages.profile.title') }}
            </link-router>
          </li>
        </div>
    </mymedel-bar>
    <div class="topbar d-flex">
      <div class="left d-flex">
        <div class="user d-flex">
          <the-breadcrumbs />
        </div>
      </div>

      <div class="right d-flex">
        <div class="language d-flex">
          <VDropdown placement="bottom" ref="languagePopover" :distance="13">
            <div class="language-popover-toggle d-flex">
              <span class="language-flag" v-text="currentLanguage" />
            </div>

            <template #popper>
              <ul class="popover-linklist">
                <li
                  v-for="lang in languages"
                  :key="lang.code"
                  class="d-block align-center"
                  v-close-popover
                >
                  <button
                    class="d-block"
                    type="button"
                    v-text="lang.label"
                    @click="changeLanguage(lang.code)"
                  />
                </li>
              </ul>
            </template>
          </VDropdown>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { appSettings } from '@/settings'
import TheBreadcrumbs from './TheBreadcrumbs.vue'
import * as utils from '../utils.js'

export default {
  name: 'ThePortalHeader',

  components: {
    TheBreadcrumbs
  },

  computed: {
    user () {
      return this.$store.getters['user/user']
    },

    currentLanguage () {
      const entries = appSettings.languages

      return Object.keys(entries).find(item => {
        return item === this.$i18n.locale
      }) || this.$i18n.locale
    },

    languages () {
      const entries = appSettings.languages

      return utils.sortListByLabel(Object.keys(entries)
        .filter(item => item !== this.$i18n.locale)
        .map(key => ({
          code: key,
          label: entries[key]
        })))
    },

    logoutCallback () {
      return `/logoutCallback?language=${this.currentLanguage}`
    },

    activeService () {
      return {
        id: 'portal',
        title: this.$t('topBar.portal'),
        path: 'dashboard',
        icon: 'icon-med-el-icon_dashboard'
      }
    }
  },

  data () {
    return {
      accessToken: ''
    }
  },
  async mounted () {
    this.accessToken = await this.$auth0.getAccessTokenSilently()
  },

  methods: {
    async changeLanguage (code) {
      this.$refs.languagePopover.hide()
      if (this.$auth0.isAuthenticated) {
        try {
          await this.$store.dispatch('user/updateUserProfile', { language: code })
        } catch (error) {
          // ignore this error, since it is not so critical
        }
      }
      this.$router.push({ name: this.$route.name, params: { lang: code }, query: this.$route.query })
      this.$i18n.locale = code
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

 .popover-linklist {
   /* use as much space as possible, and remove some pixels because of positioning (so that hte whole list is visible) */
   /* also leave enough space for the header, so popper does not reposition the popover element automatically */
  max-height: calc(50vh);
  overflow-y: auto;
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: 100;
  flex-direction: column;

  .list {
    padding: 10px;
  }
  .topbar {
    width: 100%;
    min-height: 30px;
    border-bottom: 1px solid $color-grey-300;
    background: $color-red-500;
    color: white;
    justify-content: space-between;
    margin-top: 59px;

    a {
      color: white;
    }

      @include media('>=sm') {
      margin-top: 72px;
    }
  }

  .user,
  .language {
    padding: 8px 9px;
    font-size: 16px;
    line-height: 1;
    align-items: center;
    margin-inline-start: 11px;
    margin-inline-end: 12px;

    @include media('>=sm') {
      margin-inline-start: 31px;
      margin-inline-end: 32px;
    }

    &-name {
      padding-left: 1rem;
    }

    &-flag {
      text-transform: uppercase;
    }

    &-popover-toggle {
      position: relative;
      align-items: center;
      cursor: pointer;
      padding-right: 20px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
        border-top: 4px solid white;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }

    /* user icon top is smaller than other icons*/
    .icon::before {
      font-size: 14px;
      border: 1px solid;
      padding: 2px;
      margin: 0 4px;
    }

    @include media('>=md') {
      padding: 15px 9px;

      .icon::before {
        font-size: 20px;
        border: 1px solid;
        padding: 4px;
      }
    }
  }

  .has-border-left {
    border-left: 1px solid $color-grey-300;
  }

  .has-border-right {
    border-right: 1px solid $color-grey-300;
  }

  .v-popper.open {
    .user-popover-toggle::after,
    .language-popover-toggle::after {
      transform: translate3d(0, -50%, 0) rotate(180deg);
    }
  }
}
</style>
