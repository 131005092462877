<template>
  <ul
    v-if="breadcrumbs.length"
    class="Breadcrumbs"
  >
    <li
      v-for="(item, index) in breadcrumbs"
      :key="index"
    >
      <link-router :path="item.to" class="item">
        {{ $t(getTranslationSlug(item.text)) }}
      </link-router>

      <span class="item is-filler" v-if="index < breadcrumbs.length">
        &#x3E;
      </span>
    </li>

    <!-- <span class="item is-filler">
      &#x3E;
    </span> -->

    <li class="item is-current">
      {{ $t(getTranslationSlug(currentRoute)) }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TheBreadcrumbs',

  computed: {
    breadcrumbs () {
      return this.$route.meta.breadcrumbs || []
    },

    currentRoute () {
      return this.$route.name
    }
  },

  methods: {
    getTranslationSlug (val) {
      return `breadcrumbs.${val}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.Breadcrumbs {
  display: flex;
  list-style: none;

  .item {
    margin-right: .5em;
    color: white;
    // color: $color-grey-600;
  }
}
</style>
