<template>
  <div class="Portal">
    <the-portal-header />

    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>

    <the-portal-footer />

    <div class="overlay-top" />
  </div>
</template>

<script>
import ThePortalHeader from '@/components/ThePortalHeader.vue'
import ThePortalFooter from '@/components/ThePortalFooter.vue'

export default {
  name: 'Portal',

  components: {
    ThePortalHeader,
    ThePortalFooter
  }
}
</script>

<style lang="scss">
@import '../styles/_component-imports';

.overlay-top {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: $color-grey-300;
  transition: transform 0.3s $transition-curve-acceleration;
  transform-origin: top center;
  transform: scale3d(1, 0, 1);
  z-index: 10;
}

.overlay-down-enter ~ .overlay-top,
.overlay-down-leave-to ~ .overlay-top {
  transform: scale3d(1, 0, 1);
}

.overlay-down-enter-active ~ .overlay-top,
.overlay-down-leave-active ~ .overlay-top {
  transform: scale3d(1, 1, 1);
}

.overlay-down-enter-active ~ .overlay-top {
  transition-timing-function: $transition-curve-acceleration;
}

.overlay-down-leave-active ~ .overlay-top {
  transition-timing-function: $transition-curve-deceleration;
}

.overlay-down-enter-active,
.overlay-down-leave-active {
  transition-duration: 0.5s;
}
</style>
